<template>
<div class="modal-content-pp">
    <ion-header class="headerTop">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="closeModal()">
            <ion-icon class="closebutton" src="assets/ICONOS/atras.svg"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>{{ title }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding contentt">
      <div>{{ desc }}</div>
      <div v-for="item in list" :key="item.id" 
      :style="item.mrg ? 'margin-bottom:'+item.mrg+'px;': 'margin-bottom: 5px;' ">
        {{ item.name }}
      </div>
    </ion-content>
</div>
  </template>

<script>
import { IonContent, IonHeader, IonTitle, IonToolbar, modalController } from '@ionic/vue';
import { defineComponent } from 'vue';
import { close } from 'ionicons/icons';

export default defineComponent({
  name: 'infoModal',
  props: {
    title: { type: String, default: 'Super Modal' },
    desc: { type: String, default: 'Descripción' },
    list: { type: Array, default: () => [] },
  },
  components: { IonContent, IonHeader, IonTitle, IonToolbar },
  methods: {
      closeModal() {
          modalController.dismiss({dismissed: true});
      }
  },
  setup() {
      return {close,};
  },
});
</script>

<style scoped>
.closebutton{
    color: #FFF;
}
.contentt   { 
    margin: 0 auto; 
    padding: 10px;  
    color: #000;  
    height: calc(100% - 60px);
}
.modal-content-pp {
  min-height: calc(100% - 30px);
    border: solid 1px #ccc;
}
 
</style>